import { Text } from '@latitude/text';
import cn from 'classnames';
import { forwardRef } from 'react';

import { CardProps } from 'types';

/**
 * A customizable card component.
 *
 * @component
 * @example
 * // Usage:
 * <Card title="A title">
 *   Content
 * </Card>
 *
 * @param {Object} props - The component props.
 * @param {Object} props - The component props.
 * @param {string} [props.className] - Additional CSS class name(s) for the card.
 * @param {string} [props.title] - The title of the card.
 * @param {React.ReactNode} [props.children] - The content of the card.
 * @param {React.HTMLProps<HTMLDivElement>} [props...props] - Additional props to be spread to the underlying card element.
 * @returns {React.ReactElement} The rendered Card component.
 */
const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      className,
      title,
      children,
      rawProps = {},
      'data-testid': testID,
      onClick = undefined,
    },
    ref,
  ) => {
    const isClickable = onClick !== undefined;

    return (
      <div
        onClick={onClick}
        data-testid={testID}
        ref={ref}
        className={cn(
          [
            '[&>svg]:fill-current',
            'flex',
            'flex-col',
            'min-w-min',
            'p-sizing-sys-size-24',
            'bg-color-ref-ui-surface-base',
            'text-color-ref-text-muted',
            'rounded-radius-sys-radius-8',
            {
              'cursor-pointer': isClickable,
              transition: isClickable,
              'ease-in-out': isClickable,
              'hover:bg-color-comp-table-container-hover': isClickable,
            },
          ],
          className,
        )}
        {...rawProps}
      >
        {title && (
          <Text
            variant="headingSmall"
            color="strong"
            className="pb-sizing-sys-size-24"
          >
            {title}
          </Text>
        )}
        {children}
      </div>
    );
  },
);

Card.displayName = 'Card';

export { Card, type CardProps };
